import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import './index.css';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

console.log('%cEasyCaster Cloud Restream v1.0', "color: #0fb2db; font-size: 4em; font-weight: 900;");
console.log('%cDeveloped by Live-TV Service company', "color: var(--main-color); font-size: 2em; font-weight: 900;text-decoration: underline;");
