import React, {useEffect, useState} from 'react';
import './Navigation.css';
import logo from '../../assets/images/logo.png';
import {Link} from 'react-router-dom';


function Navigation() {
    const [routes, setRoutes] = useState([
        {
            path: '/admin/users',
            label: 'Users',
            active: false
        },
        {
            path: '/admin/servers',
            label: 'Servers',
            active: false
        },
        {
            path: '/admin/apps',
            label: 'Application',
            active: false
        }
    ]);

    useEffect(() => {
        const urlPath = window.location.pathname;
        const index = routes.findIndex((route) => route.path === urlPath);
        const activeIndex = index !== -1 ? index : 0;
        setActiveItem(activeIndex);
    }, []);

    const setActiveItem = (activeIndex) => {
        const newRoutes = routes.map((route, index) => {
            route.active = false;
           if(index === activeIndex) route.active = true;
           return route;
        });

        setRoutes(newRoutes);
    }

    return (
        <div className={'navigation'}>
            {/*<img className={'navigation__logo'} src={logo}/>*/}
            <ul>
                {
                    routes.map((route, index) => (
                        <li key={route.path}>
                            <Link
                                to={route.path}
                                className={route.active ? 'active' : ''}
                                onClick={() => {
                                    setActiveItem(index);
                                }}
                            >
                                {route.label}
                            </Link>
                        </li>
                    ))
                }
            </ul>
        </div>
    );
}

export default Navigation;
